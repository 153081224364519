/* === Fira Sans - regular */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('Fira Sans Regular'), local('FiraSans-Regular'), url(/asset/fonts/FiraSans-Regular.8d4eebb33f68cfabfff42f9cd082b3b0.woff) format('woff'), url(/asset/fonts/FiraSans-Regular.8705f937b9f572f8e42072cd818c6a60.woff2) format('woff2');
}

/* === Fira Sans - 500 */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;

  src: local('Fira Sans Medium'), local('FiraSans-Medium'), url(/asset/fonts/FiraSans-Medium.c2793ea4e68fa4e48bc0574a6175840e.woff) format('woff'), url(/asset/fonts/FiraSans-Medium.b1a486bad5ab273230ff01539177eaad.woff2) format('woff2');
}

/* === Source Sans Pro - regular */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(/asset/fonts/SourceSansPro-Regular.d683bfb394f888c5820ce11fc4770fd8.woff) format('woff'), url(/asset/fonts/SourceSansPro-Regular.80c998aa03640281d556814a14e1d9c8.woff2) format('woff2');
}

/* === Clan Pro - 500 */
@font-face {
  font-family: 'ClanPro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;

  src: local('ClanPro-Bold'), local('ClanPro-Bold'), url(/asset/fonts/ClanPro-Bold.3b8cd8710560982ccc0222acab901e75.woff) format('woff'), url(/asset/fonts/ClanPro-Bold.32e33966e2332ed1650baac9ecb5f16f.woff2) format('woff2');
}

/* === Clan Pro News - regular */
@font-face {
  font-family: 'ClanPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('ClanPro-News'), local('ClanPro-News'), url(/asset/fonts/ClanPro-News.1afcd6a048104a89bd977ec19e38b364.woff) format('woff'), url(/asset/fonts/ClanPro-News.0f60c03dba33549ff47458b95131fd00.woff2) format('woff2');
}

/* === EOS fonts */

@font-face {
  font-family: 'EOS Bold';
  src: url(/asset/fonts/EOS-Bold.fbe1f1528644d2438d91b9abffd520c6.woff2) format('woff2'), url(/asset/fonts/EOS-Bold.0b52c6aab5f5430683495b797ddfdf03.woff) format('woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'EOS Bold Italic';
  src: url(/asset/fonts/EOS-BoldItalic.037e9f98aed12e8a72cca356f822f4af.woff2) format('woff2'), url(/asset/fonts/EOS-BoldItalic.693e3c4740e534087cddf2bbf619d259.woff) format('woff');
}

@font-face {
  font-family: 'EOS Light';
  src: url(/asset/fonts/EOS-Light.38101ca89cc1312c0cbc3031b65881e1.woff2) format('woff2'), url(/asset/fonts/EOS-Light.4440f67266f4416439524cceffa5d09b.woff) format('woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'EOS Light Italic';
  src: url(/asset/fonts/EOS-LightItalic.4179356c29718f02195474debdc01ac8.woff2) format('woff2'), url(/asset/fonts/EOS-LightItalic.b54849a102942a77d918ad12f6ae1193.woff) format('woff');
}

@font-face {
  font-family: 'EOS Regular';
  src: url(/asset/fonts/EOS-Regular.0f51daca20e54c2dde3e70775506da0a.woff2) format('woff2'), url(/asset/fonts/EOS-Regular.04b85c804f9208018a4ae69f82dbe7fe.woff) format('woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'EOS Regular Italic';
  src: url(/asset/fonts/EOS-RegularItalic.f4c4002c90710f127ff9649104dd7f2a.woff2) format('woff2'), url(/asset/fonts/EOS-RegularItalic.4056a332d49ec62078fdf23cb64c366b.woff) format('woff');
}

@font-face {
  font-family: 'EOS Ultrabold';
  src: url(/asset/fonts/EOS-Ultrabold.40aba36f3dbd630876520d751ec88031.woff2) format('woff2'), url(/asset/fonts/EOS-Ultrabold.7996ec791d8f976061ed120c88d4378b.woff) format('woff');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'EOS UltraboldItalic';
  src: url(/asset/fonts/EOS-UltraboldItalic.04f1a6d50d89485d7e4e8d847a8332ed.woff2) format('woff2'), url(/asset/fonts/EOS-UltraboldItalic.7d9154f63fdb792cf47274d537e1a631.woff) format('woff');
}

/* === Roboto */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto Regular'), local('Roboto-Regular'), url(/asset/fonts/Roboto-Regular.24fb1c66ecb051afe0d7fb1e507e5d6a.woff) format('woff'), url(/asset/fonts/Roboto-Regular.15d9f621c3bd1599f0169dcf0bd5e63e.woff2) format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(/asset/fonts/Roboto-Medium.ba6957f95d91472aa111983427b7d571.woff) format('woff'), url(/asset/fonts/Roboto-Medium.3a44e06eb954b96aa043227f3534189d.woff2) format('woff2');
}
